import theAxios from 'axios'
// import {
// 	Toast
// } from 'vant';
const apis = {
  // production: 'https://tuapi.kyd.uyl.cn/', // 测试 (生成环境)
  production: 'https://v3userkeydeal.api.harsineos.com/', // 正式 (正式环境)
  development: '/userIp', // 本地 (开发环境)
  
}
const axios = theAxios.create({
	// baseURL: 'https://qdwx.hisense-plaza.cn',
	baseURL: process.env.NODE_ENV === 'production' ? apis.production : apis.development,
	// baseURL:'/testIp',
	timeout: 20000
})
// 2.请求拦截器
axios.interceptors.request.use(config => {
	// Toast.loading({
	// 	duration: 0,
	// 	forbidClick: true,
	// });
	//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
	// config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
	// config.headers = {
	// // 'Content-Type': '*' //配置请求头
	// 	// 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8', //配置请求
		
	// 	// 'x-api-key': localStorage.getItem('accessToken')
	// }
	//如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
	//const token = getCookie('名称');//这里取token之前，你肯定需要先拿到token,存一下
	//    if(token){
	//       config.params = {'token':token} //如果要求携带在参数中
	//       config.headers.token= token; //如果要求携带在请求头中
	//     }
	return config
}, error => {
	Promise.reject(error)
})
axios.interceptors.response.use(response => {
	//接收到响应数据并成功后的一些共有的处理，关闭loading等
	// Toast.clear()
	// if (response.data.code != 200) {
	// 	Toast({
	// 		message: response.data.msg
	// 	})
	// }
	return response.data
}, error => {
	/***** 接收到异常响应的处理开始 *****/
	// Toast.clear()
	return Promise.resolve(error.response)
})
export default ({
	url,
	method = 'GET',
	params = {},
	data = {},
	headers = {}
}) => {
	return axios({
		url,
		method,
		params,
		data,
		headers
	})
}
